import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Index.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
            },
            {
                path: '/work',
                name: 'Work',
                component: () => import(/* webpackChunkName: "work" */ '@/views/Work.vue')
            },
            {
                path: '/services',
                name: 'Services',
                component: () => import(/* webpackChunkName: "services" */ '@/views/Services.vue')
            },
            {
                path: '/movies',
                name: 'Movies',
                component: () => import(/* webpackChunkName: "movies" */ '@/views/Movies.vue')
            },
            {
                path: '/artists',
                name: 'Artists',
                component: () => import(/* webpackChunkName: "artists" */ '@/views/Artists.vue')
            }            
        ]
    },
    
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

export default router
